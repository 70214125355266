/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
	init: (app, gsap, ScrollTrigger, Kira) => {
		/*
		|
		| Constants
		|-----------
		*/
        const
            html              = document.querySelector('html'),
            body              = document.querySelector('body'),
            $body             = $('body'),
            $pageLoader       = $('.loader'),
            scrollAreas       = document.querySelectorAll('.scroll-area'),
            $splitItems       = $body.find('[data-splittext]')
        ;


        /*
		|
		| SplitText
		|------------
        */
        $.each($splitItems, function() {
            const $item = $(this);
            const type = $item.data('splittext') ? $item.data('splittext') : 'words, chars';
            const split = new SplitText($item, { type: type, linesClass: 'item-line', wordsClass: 'item-word', charsClass: 'item-char' });

            $item[0]['split'] = split;
        });

        $body.on('dom:ready', () => {
            $splitItems.addClass('split-ready')
        })


		/*
        |
        | Scroll Areas
        |---------------
        */
		Array.prototype.forEach.call(scrollAreas, element => {
			Scrollbar.init(element);
		});


        /*
		|
		| Loader
		|---------
        */
        if (sessionStorage.getItem('loaded_once') === null) {
            sessionStorage.setItem('loaded_once', 'loaded_once');
        }

        if ($pageLoader.hasClass('--active')){

            const loaderEl = document.querySelector('.loader')
            const loaderTl = gsap.timeline({ paused: true, onComplete: () => loaderEl.remove()})
            loaderTl.to(loaderEl, {y: '-100%', duration: 0.6, ease: 'Power2.easeOut'})

            const zero= {val:0};
            const front = document.querySelector('.percentage__front')
            const back = document.querySelector('.percentage__back')
            const p = document.querySelector('.progress')
            
            $(window).on('load', function(){
                gsap.to(zero,{duration:3,val:100,onUpdate:countNumber, onComplete: () => {
                    setTimeout(() => {
                        loaderTl.play()
                    }, 1000);
                }, ease: "none"})
            })

            function countNumber() {
                var final = gsap.utils.snap(1, zero.val);
                front.innerHTML = final;
                back.innerHTML = final;

                gsap.to(p, {scaleX: Math.abs(final / 100)})
            }

        } else {
            $(window).on('load', function(){
                app.dispachEvent(body, 'dom:ready');
            })
        }

        // Menu
        ////////////////////////////////////////////////////////////
        const menuTl = gsap.timeline({ paused: true })
        const menuEl = document.querySelector('.menu');
        const menuItems = document.querySelectorAll('.menu__navigation ul li');
        const menuBtn = document.querySelector('.burger__btn');
        const shapesMenu = menuEl.querySelectorAll('.shape');

        menuTl.from(menuEl, { autoAlpha: 0, duration: 0.4, ease: 'Power2.easeOut' }, "start")
              .from(shapesMenu, { y: "random(20, 70)", autoAlpha: 0,duration: 1, stagger: 0.1, ease: 'Power2.easeOut'}, '-=0.3')
              .from(menuItems, { y: 40, autoAlpha: 0, duration: 0.6, stagger: 0.1, ease: 'Power2.easeOut'}, '-=1')
              .from('.menu_networks', { y: 40, autoAlpha: 0, duration: 0.6, ease: 'Power2.easeOut'}, '-=0.6')
              
        menuBtn.addEventListener('click', function(e){
            e.preventDefault();

            if (this.classList.contains('--active')) {
                menuTl.timeScale(2).reverse();
                this.classList.remove('--active');
                document.querySelector('html').classList.remove('--hidden');
            } else {
                menuTl.timeScale(1).play();
                this.classList.add('--active');
                document.querySelector('html').classList.add('--hidden');
            }

        })

        //anchor link
        ////////////////////////////////////////////////////////////
        const anchorLinks = document.querySelectorAll('.anchor__link');

        if (anchorLinks) {
            anchorLinks.forEach(link => {

                const target = link.getAttribute('href');
                const offsetPosition = document.querySelector('.header').offsetHeight
                
                link.addEventListener('click', e => {
                    e.preventDefault();
                    gsap.to(window, {duration: 0.8, scrollTo: {y: target, offsetY: offsetPosition}});
    
                    //menu 
                    menuTl.timeScale(2).reverse();
                    menuBtn.classList.remove('--active');
                    document.querySelector('html').classList.remove('--hidden');
                })
    
            })
        }

        //Horizontal scroll
        ////////////////////////////////////////////////////////////
        const container = document.querySelector(".horizontal");
        const header = document.querySelector('.header');
        const windowWidth = document.documentElement.clientWidth;
        let scrollTween = null;
        const durationLayer = 0.3;

        if (container) {
            if (windowWidth > 991) {
                scrollTween = gsap.to(container, {
                    x: () => -(container.scrollWidth - document.documentElement.clientWidth) + "px",
                    ease: "none",
                    scrollTrigger: {
                        trigger: container,
                        invalidateOnRefresh: true,
                        pin: true,
                        scrub: 1,
                        onUpdate: self => {
                            document.querySelector('.layout__inner').classList.add('--fixed-bg');
                            if (self.progress === 0 || self.progress === 1) {
                                document.querySelector('.layout__inner').classList.remove('--fixed-bg');
                            }
                        },
                        start: () => `-=${document.querySelector('.header').offsetHeight}px`,
                        end: () => container.scrollWidth - document.documentElement.clientWidth,
                        onEnterBack: () => {
                            gsap.to("body", {backgroundColor: '#9B4596', overwrite: 'auto', duration: durationLayer})
                            gsap.to(".header", {backgroundColor: '#9B4596', overwrite: 'auto', duration: durationLayer})
                        },
                    }
                })
            } else {
    
                scrollTween = gsap.to(container, {
                    scrollTrigger: {
                        trigger: container,
                        invalidateOnRefresh: true,
                        onEnterBack: () => {
                            gsap.to("body", {backgroundColor: '#9B4596', overwrite: 'auto', duration: durationLayer})
                            gsap.to(".header", {backgroundColor: '#9B4596', overwrite: 'auto', duration: durationLayer})
                        },
                    }
                })
    
            }
    
            function fixedBg(property){
    
                const layoutInner = document.querySelector('.layout__inner');
    
                if (property === 'add') {
                    layoutInner.classList.add('--fixed-bg');
                } else {
                    layoutInner.classList.remove('--fixed-bg');
                }
                
            }
        }

        //Layer
        /////////////////////////////////////////////////////
        const layerSections = document.querySelectorAll('.layer__section');
        const layerSectionsHorizontal = document.querySelectorAll('.layer__section-horizontal');
        const headerHeight = document.querySelector('.header').offsetHeight

        function handleLayerVertical(array){

            array.forEach((section, i) => {

                const layer = section.dataset.layer;
                const prevColor = i === 0 ? "#343BD3" : array[i - 1].dataset.layer;
    
                ScrollTrigger.create({
                    trigger: section,
                    start: `start-=${headerHeight}px`,
                    end: `bottom-=${headerHeight}px`,
                    onEnter: () => {
                        gsap.to("body", {backgroundColor: layer, overwrite: 'auto', duration: durationLayer})
                        gsap.to(".header", {backgroundColor: layer, overwrite: 'auto', duration: durationLayer})
                        gsap.to(".form__container", {backgroundColor: layer, overwrite: 'auto', duration: durationLayer})
                    },
                    onLeaveBack: () => {
                        gsap.to("body", {backgroundColor: prevColor, overwrite: 'auto', duration: durationLayer})
                        gsap.to(".header", {backgroundColor: prevColor, overwrite: 'auto', duration: durationLayer})
                        gsap.to(".form__container", {backgroundColor: prevColor, overwrite: 'auto', duration: durationLayer})
                    }
                });
    
            })

        }

        function handleLayerHorizontal(collections){

            collections.forEach((element, i) => {

                const layer = element.dataset.layer;
                const prevColor = i === 0 ? "#343BD3" : collections[i - 1].dataset.layer;

                ScrollTrigger.create({
                    scroller: container,
                    trigger: element,
                    horizontal: true,
                    containerAnimation: scrollTween,
                    start: () => 'left',
                    end: "center 51%",
                    onEnter: () => {
                        gsap.to("body", {backgroundColor: layer, overwrite: 'auto', duration: durationLayer})
                        gsap.to(".header", {backgroundColor: layer, overwrite: 'auto', duration: durationLayer})
                        gsap.to(".form__container", {backgroundColor: layer, overwrite: 'auto', duration: durationLayer})
                    },
                    onLeaveBack: () => {
                        gsap.to("body", {backgroundColor: prevColor, overwrite: 'auto', duration: durationLayer})
                        gsap.to(".header", {backgroundColor: prevColor, overwrite: 'auto', duration: durationLayer})
                        gsap.to(".form__container", {backgroundColor: prevColor, overwrite: 'auto', duration: durationLayer})
                    },
                });
            });

        }

        if (layerSections || layerSectionsHorizontal) {
            if (windowWidth > 991) {
                handleLayerVertical(layerSections)
                handleLayerHorizontal(layerSectionsHorizontal)
            } else {
                handleLayerVertical(layerSections)
                handleLayerVertical(layerSectionsHorizontal)
            }
        }

        //Scroll Spy
        /////////////////////////////////////////////////////
        const sectionLinks = document.querySelectorAll('.header__navigation a:not(.burger__btn)');

        sectionLinks.forEach(link => {

            let target = link.getAttribute('href');
            let sectionAttached = document.getElementById(target.substring(1));
            let startPosition = (document.querySelector(".students").offsetTop + document.querySelector(".students").offsetHeight) - (headerHeight + 10);

            if (target === "#retrospective") {
                ScrollTrigger.create({
                    trigger: sectionAttached,
                    start: () => startPosition,
                    end: () => `top+=${container.offsetHeight * 3}`,
                    onEnter: () => {
                        link.classList.add('--active');
                    },
                    onLeave: () => {
                        link.classList.remove('--active');    
                    },
                    onEnterBack: () => {
                        link.classList.add('--active');
                    },
                    onLeaveBack: () => {
                        link.classList.remove('--active');
                    }
                });
            } else {
                ScrollTrigger.create({
                    trigger: sectionAttached,
                    start: () => `top-=${headerHeight + 10}px`,
                    end: () => 'bottom-=20%',
                    onEnter: () => {
                        link.classList.add('--active');
                    },
                    onLeave: () => {
                        link.classList.remove('--active');    
                    },
                    onEnterBack: () => {
                        link.classList.add('--active');
                    },
                    onLeaveBack: () => {
                        link.classList.remove('--active');
                    }
                });
            }

            

        })
        

        /*
        |
		| Kira
		|-----------
        */
        const kira = new Kira({
            loadEvent: [body, 'dom:ready'],
            scrollTrigger: {
                markers: false,
                //scroller: scrollContainerSelector,
            },
            tweenParams: {
                start: '-=0.6'
            }
        });

        /*
        | fadeInUp
        |-----------
        */
        kira.add('fadeInUp', (item, timeline, start) => {
            timeline.fromTo(item, 1.3, { y: 50 }, { y: 0, autoAlpha: 1, ease: 'easeSmooth' }, start)
        });

        /*
        | fadeInUp.parallax.sm
        |-----------------------
        */
        kira.add('fadeInUp.parallax', (item, timeline, start) => {
            timeline.fromTo(item, 0.8, { y: 100 }, { y: 0, autoAlpha: 1, ease: 'Sine.easeOut' }, start)
        });

        /*
        | splitline
        |------------
        */
        kira.add('splitline', (item, timeline, start) => {
            const delay = item.dataset.delay ? item.dataset.delay : 0.012;

            item.querySelectorAll('.item-line').forEach( line => {
                timeline.from($(line).find('> div, > em, > span'), 1.35, { y: '101%', ease: 'easeSmooth' }, start)
            } )
        });

        /*
        | splittext.long
        |-----------------
        */
        kira.add('splittext.long', (item, timeline, start) => {
            const delay = item.dataset.delay ? item.dataset.delay : 0.01;

            timeline.staggerFrom(item.split.chars, 0.8, {y: 5, opacity: 0, ease: 'Sine.ease0ut' }, delay, start)
        });

        /*
        | fadeInLeft.parallax.sm
        |-------------------------
        */
        kira.add('fadeInLeft.stagger.sm', ($item, timeline, start) => {
            timeline.staggerFromTo($item.querySelectorAll('[data-stagger-item]'), 0.6, { x: 20 }, { autoAlpha: 1, x: 0, ease: 'Power1.easeOut' }, '0.1', start)
        });

        /*
        | fadeInUp.parallax.sm
        |-------------------------
        */
        kira.add('fadeInUp.stagger.sm', ($item, timeline, start) => {
            timeline.staggerFromTo($item.querySelectorAll('[data-stagger-item]'), 1, { x: 20 }, { opacity: 1, x: 0, ease: 'Power1.easeOut' }, '0.05', start)
        });

        kira.init();

        //////////////////////////////////////////////////
        // Parallax
        //
        // Use :
        // - put [data-parallax-trigger] on parent section
        // - data-parallax and data-speed on item you want to translate
        // - you can specify direction with : data-direction="horizontal" (default vertical)
        //
        //////////////////////////////////////////////////
        if (window.innerWidth > 991) {
            
            gsap.utils.toArray("[data-parallax-trigger]").forEach((section) => {
                gsap.utils.toArray(section.querySelectorAll("[data-parallax]")).forEach((parallax) => {
    
                    const depth = parallax.dataset.speed;
                    const direction = parallax.dataset.direction;
                    const movement = -(parallax.offsetHeight * depth);
                    const hasHorizontal = section.closest('.horizontal');
    
                    let scrollTriggerDefault = {
                        trigger: section,
                        scrub: true
                    }
    
                    let horizontalScrolltrigger = {
                        trigger: section,
                        scrub: true,
                        horizontal: true,
                        containerAnimation: scrollTween
                    }
    
                    if (direction === "horizontal") {
    
                        gsap.fromTo(
                            parallax,
                            {
                              x: -movement
                            },
                            {
                              x: movement,
                              ease: "none",
                              scrollTrigger: hasHorizontal ?  horizontalScrolltrigger : scrollTriggerDefault
                            }
                        );
    
                    } else if(direction === 'rotation'){
    
                        gsap.fromTo(
                            parallax,
                            {
                              rotation: -movement
                            },
                            {
                              rotation: movement,
                              ease: "none",
                              scrollTrigger: hasHorizontal ?  horizontalScrolltrigger : scrollTriggerDefault
                            }
                        );
    
                    } else {
    
                        gsap.fromTo(
                            parallax,
                            {
                              y: -movement
                            },
                            {
                              y: movement,
                              ease: "none",
                              scrollTrigger: hasHorizontal ?  horizontalScrolltrigger : scrollTriggerDefault
                            }
                        );
    
                    }
    
                });
            });

        }
        
	}
}
