/*** IMPORTS FROM imports-loader ***/
var define = false;

/*
|
| Importing Libs
|------------------
*/
import Swiper from 'swiper/js/swiper.min';
import gsap from "gsap";
import SplitText from "@lib/gsap-pro/SplitText";
import ScrollTo from "gsap/ScrollToPlugin";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollTo);

/*
|
| Importing Components
|-----------------------
*/
import Kira from '@components/kira.js';

/*
|
| Importing Utils
|-------------------
*/
import Router from '@utils/router.js';

/*
|
| Importing App files
|----------------------
*/
import * as app from '@components/global.js';
import main from '@pages/main.js';
import animations from '@pages/animations.js';

/*
|
| Routing
|----------
*/
const routes = new Router([
    {
      'file': animations,
      'dependencies': [app, gsap, ScrollTrigger, Kira]
    },
	  {
      'file': main,
      'dependencies': [app, Swiper]
    },
]);

/*
|
| Run
|------
*/
(($) => { routes.load() })(jQuery);

