/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
	init: (app, Swiper) => {
		/*
		|
		| Constants
		|-----------
		*/
        const
            $body         = $('body')
		;


		const sliderPresents = document.querySelector('.slider__presents');

		if (sliderPresents) {
			const swiperPresents = new Swiper(sliderPresents, {
				slidesPerView: 1,
				spaceBetween: 30,
				breakpoints: {
					768: {
						slidesPerView: 2,
						spaceBetween: 30
					},
					992: {
						slidesPerView: 3,
						spaceBetween: 30,
					},
					1200: {
						slidesPerView: 'auto',
						spaceBetween: 90,
					}
				},
				pagination: {
					el: '.swiper-pagination',
					type: 'bullets',
					clickable: true
				},
				navigation: {
					nextEl: '.controls__mobile.--right',
					prevEl: '.controls__mobile.--left',
				},
			})

			const controlsSwiper = document.querySelector('.controls');

			controlsSwiper.addEventListener('click', function(e){
				e.preventDefault();
				swiperPresents.slideNext();
			})
		}

		
        
	}
}
